(function($)
{
	"use strict";

	var $header = $('#header');
	var $body = $('body');
	var $window = $(window);

	/**
	 * Click on search button
	 */
	$header.find('.searchbutton').click(function(e)
	{
		e.preventDefault();
		if (!$header.hasClass('search-open'))
		{
			$header.addClass('search-open');
			$body.addClass('search-open');
			setTimeout(function() { $('#searchbar input').focus().select(); }, 50);
		}
		else
		{
			$header.removeClass('search-open');
			$body.removeClass('search-open');
		}
		return false;
	});
	$('#searchbar input').keyup(function(e)
	{
		if (e.keyCode == 27)
		{
			$header.removeClass('search-open');
			$body.removeClass('search-open');
		}
	});


	/**
	 * About overlay
	 */
	$('nav.metanav a.about').click(function(e)
	{
		e.preventDefault();
		$('body').addClass('about-open');
		$('#about-overlay').fadeIn(200);/*.click(function(e)
		{
			if ($(e.target).closest('a').length == 0)
			{
				$('#about-overlay').fadeOut(200);
				$('body').removeClass('about-open');
			}
		});*/

		return false;
	});


	/**
	 * Close button
	 */
	$('#header .closebutton').click(function(e)
	{
		e.preventDefault();
		$('body').removeClass('about-open');
		$('#about-overlay').fadeOut(200);
		return false;
	});


	/**
	 * Min height for main nav in mobile view
	 */
	if ($window.width() < 1140)
	{
		$('#header nav.mainnav').css('min-height', $window.height() - $header.height() + 'px');
	}

})(jQuery);
